<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        a) Given that the
        <stemble-latex content="$\text{pK}_\text{a}$" />
        of fictional acid is
        <number-value :value="pKa" />
        at
        <stemble-latex content="$25^\circ\text{C,}$" />
        determine the
        <stemble-latex content="$\text{K}_\text{a}$" />
        value for the acid at that temperature.
      </p>

      <calculation-input
        v-model="inputs.Ka"
        class="mb-5"
        prepend-text="$\text{K}_\text{a}:$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) What is the value of
        <stemble-latex content="$\text{K}_\text{b}$" />
        for the conjugate base of this acid at
        <stemble-latex content="$25^\circ\text{C?}$" />
      </p>

      <calculation-input
        v-model="inputs.Kb"
        class="mb-5"
        prepend-text="$\text{K}_\text{b}:$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">c) Explain how you solved parts a) and b) of this question.</p>

      <p class="mb-n5">
        <s-textarea v-model="inputs.explainedResponse" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '@/tasks/components/NumberValue.vue';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'Question544AI',
  components: {
    STextarea,
    NumberValue,
    StembleLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Ka: null,
        Kb: null,
        explainedResponse: null,
      },
    };
  },
  computed: {
    pKa() {
      return this.taskState.getValueBySymbol('pKa').content;
    },
  },
};
</script>
